import React from 'react'
import { Form, Image } from 'react-bootstrap'

import LeftCircleArrowDark from '../assets/images/left-arrow-dark.svg'
import LeftCircleArrowLight from '../assets/images/left-arrow-light.svg'
import RightCircleArrowDark from '../assets/images/right-arrow-dark.svg'
import RightCircleArrowLight from '../assets/images/rigth-arrow-light.svg'

interface Props {
  page: number
  tableCount: number
  setPage: any
  itemsPerPage?: number
  backgroundColor?: string
}
const Pagination: React.FC<Props> = ({
  tableCount,
  page,
  setPage,
  itemsPerPage,
  backgroundColor,
}) => {
  return (
    <div className="d-flex align-items-center mt-3">
      <p className="sub-title-clr font-size-16 me-auto">
        {!(tableCount === 0) && (
          <span className="ms-1">
            {Number(page) === 1
              ? 1
              : (Number(page) - 1) * (itemsPerPage || 10) + 1}
            - {Math.min(Number(page) * (itemsPerPage || 10), tableCount)} of{' '}
            {tableCount}
          </span>
        )}
      </p>

      {/* <div
        className={`mt-2 d-flex align-items-center ${
          backgroundColor || 'bg-body-color'
        }`}
      >
        <Form.Select
          value={page}
          size="sm"
          className="pagination-select"
          onChange={(e) => {
            setPage(+e.target.value - 1)
          }}
        >
          {Array.from(
            { length: Math.ceil(tableCount / (itemsPerPage || 10)) },
            (v, i) => +i + 1
          )?.map((pageNum) => (
            <option key={pageNum} value={pageNum}>
              {pageNum}
            </option>
          ))}
        </Form.Select>
      </div> */}

      <p className="ms-4 font-size-16 mb-0 sub-title-clr">
        <span
          className={`${page === 1 ? 'not-allowed' : 'pointer'}`}
          onClick={() => {
            if (page === 1) return
            setPage(page - 1)
          }}
        >
          <Image
            src={page === 1 ? LeftCircleArrowLight : LeftCircleArrowDark}
          />
        </span>
        <span className="mx-2">
          {page}/{' '}
          {tableCount ? Math.ceil(tableCount / (itemsPerPage || 10)) : 0}
        </span>
        <span
          className={`${
            page === Math.ceil(tableCount / (itemsPerPage || 10))
              ? 'not-allowed'
              : 'pointer'
          }`}
          onClick={() => {
            if (page === Math.ceil(tableCount / (itemsPerPage || 10))) return
            setPage(page + 1)
          }}
        >
          <Image
            src={
              page === Math.ceil(tableCount / (itemsPerPage || 10))
                ? RightCircleArrowLight
                : RightCircleArrowDark
            }
          />
        </span>
      </p>
    </div>
  )
}

export default Pagination
