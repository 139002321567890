import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Form, Image, InputGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import OpenSearchIcon from '../../../assets/images/open-search-icon.png'
import SearchIcon from '../../../assets/images/search-icon.svg'
import {
  getBusinessCategoryListThunk,
  getCityListThunk,
  getCompaniesListThunk,
} from '../../../redux/companies/companiesActions'
import { Store } from '../../../redux/rootType'
import { dispatch } from '../../../redux/store'
/**
 * ==================================================
 * Global code
 * ==================================================
 */

const CompanyFilter = ({
  industryType,
  setIndustryType,
  location,
  setLocation,
  search,
  isBookmark,
  isClient,
  connectionStatus,
  connectionRequestBy,
  locationPayload,
  industryTypeListPayload,
}: {
  search: string
  locationPayload: any
  industryTypeListPayload: any
  industryType: any
  setIndustryType: any
  location: any
  setLocation: any
  isBookmark?: boolean
  isClient?: boolean
  connectionStatus?: string
  connectionRequestBy?: string
}) => {
  /**
   * ==================================================
   * const variable
   * ==================================================
   */

  /**
   * ==================================================
   * Data from redux store
   * ==================================================
   */

  const businessCategoryList = (
    useSelector(
      (state: Store) => state?.companiesReducer?.businessCategoryList
    ) || []
  )?.map((val) => ({ ...val, isChecked: false }))

  const cityList = (
    useSelector((state: Store) => state?.companiesReducer?.cityList) || []
  )?.map((val) => ({ ...val, isChecked: false }))

  /**
   * ==================================================
   * Component state
   * ==================================================
   */
  // const [expandIndustrySearch, setExpandIndustrySearch] = useState(false)
  /**
   * ==================================================
   * const based on component state and redux state
   * ==================================================
   */

  /**
   * ==================================================
   * functionality
   * ==================================================
   */
  const updateListOfIndustryType = (itemIndex, isChecked) => {
    const updatedListOfItems = [...industryType]
    updatedListOfItems[itemIndex].isChecked = isChecked
    setIndustryType(updatedListOfItems)
  }

  const updateListOfLocation = (itemIndex, isChecked) => {
    const updatedListOfItems = [...location]
    updatedListOfItems[itemIndex].isChecked = isChecked
    setLocation(updatedListOfItems)
  }

  const resetFilter = () => {
    setLocation(cityList)
    setIndustryType(businessCategoryList)
    dispatch(
      getCompaniesListThunk(
        search,
        undefined,
        undefined,
        isBookmark,
        isClient,
        connectionStatus,
        connectionRequestBy
      )
    )
  }

  const appliedFilter = () => {
    dispatch(
      getCompaniesListThunk(
        search,
        locationPayload || undefined,
        industryTypeListPayload || undefined,
        isBookmark || undefined,
        isClient || undefined,
        connectionStatus ?? undefined,
        connectionRequestBy ?? undefined
      )
    )
  }

  /**
   * ==================================================
   * use effect handling
   * ==================================================
   */
  useEffect(() => {
    dispatch(getCityListThunk(true))
    dispatch(getBusinessCategoryListThunk())
  }, [])

  useEffect(() => {
    setLocation(cityList)
    setIndustryType(businessCategoryList)
  }, [cityList?.length, businessCategoryList?.length])

  return (
    <div className="card">
      <div className="d-flex p-4">
        <h6 className="mb-0">Filter by</h6>
        {/* <h6 className="mb-0 ms-auto text-primary">Clear All</h6> */}
      </div>
      <div
        className="card-body p-4"
        style={{ height: 'calc(100vh - 330px)', overflowY: 'scroll' }}
      >
        <div className="d-flex mb-3">
          {/* {expandIndustrySearch ? (
            <> */}
          <p className="mb-0 fw-bold">Industry Type</p>
          {/* <Image
            src={OpenSearchIcon}
            className="ms-auto pointer"
            onClick={() => setExpandIndustrySearch(!expandIndustrySearch)}
          />
          </>
          ) : (
            <InputGroup className="ms-auto search-box">
              <Form.Control
                placeholder="Search job role or company"
                aria-label="Search"
                aria-describedby="basic-addon1"
              />

              <InputGroup.Text
                id="basic-addon1"
                onClick={() => setExpandIndustrySearch(!expandIndustrySearch)}
                className="pointer"
              >
                <i className="fa fa-times" aria-hidden="true"></i>
              </InputGroup.Text>
            </InputGroup>
          )} */}
        </div>
        <div style={{ maxHeight: '150px', overflowY: 'scroll' }}>
          {industryType?.map((item, index) => (
            <Form.Check
              key={item?.id}
              type={'checkbox'}
              id={'Ind' + item?.id.toString()}
              checked={item.isChecked}
              label={item?.name}
              onChange={() => updateListOfIndustryType(index, !item.isChecked)}
            />
          ))}
        </div>
        {/* <small className="text-primary fw-bold mt-2 d-block">+16 more</small> */}
        <div className="saprater my-3" />

        <div className="d-flex mb-3">
          <p className="mb-0 fw-bold">Company Location</p>
          {/* <Image src={OpenSearchIcon} className="ms-auto" /> */}
        </div>
        <div style={{ maxHeight: '150px', overflowY: 'scroll' }}>
          {location?.map((item, index) => (
            <Form.Check
              key={item?.id}
              type={'checkbox'}
              id={'loc' + item?.id.toString()}
              checked={item.isChecked}
              label={item?.name}
              onChange={() => updateListOfLocation(index, !item.isChecked)}
            />
          ))}
        </div>
        <div className="saprater my-3" />
        {/* <p className="mb-3 fw-bold">Active Openings</p>
        <div className="d-flex align-items-center justify-content-center">
          <Form.Group
            className="form-group"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Min</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={'0'}
              placeholder="Min"
              className="form-control"
            />
          </Form.Group>
          <span className="mx-2">-</span>
          <Form.Group
            className="form-group"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Max</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={'0'}
              placeholder="Max"
              className="form-control"
            />
          </Form.Group>
        </div> */}
      </div>
      <div className="card-footer d-flex justify-content-around">
        <button
          className="btn  btn-light px-4 py-2"
          type="button"
          onClick={resetFilter}
          disabled={!(locationPayload || industryTypeListPayload)}
        >
          Reset
        </button>
        <button
          className="btn  btn-primary px-4 py-2 ms-3"
          type="button"
          onClick={appliedFilter}
          disabled={!(locationPayload || industryTypeListPayload)}
        >
          Apply
        </button>
      </div>
    </div>
  )
}

export default CompanyFilter
